<mat-toolbar class="nav" id="top-nav">
    <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start">
        <div fxFlex="10" fxLayoutAlign="start start">
            <div class="nav-brand" exceptclick fxLayout="row" fxLayoutAlign="center center">
                <img fxFlex src="assets/images/logo.png" [routerLink]="'/'" />
            </div>
        </div>
        <div fxFlex fxLayoutAlign="end center" class="right-section">
            <span class="date">{{date | date: 'fullDate'}}</span>
            <mat-icon [routerLink]="['/notifications']" class="notifications">notifications<div *ngIf="hasNew"
                    class="unread"></div>
            </mat-icon>
            <div class="border">&nbsp;</div>
            <img [src]="pictureLink || 'assets/images/300-1.jpg'" class="profile-image user-menu-icon"
                onerror="this.src='assets/images/profile.png'" [matMenuTriggerFor]="userMenu" />
            <mat-menu #userMenu="matMenu" class="user-menu" xPosition="before" yPosition="above"
                [overlapTrigger]="false">
                <a mat-menu-item (click)="viewProfile()">
                    <mat-icon>person</mat-icon>
                    <span>{{ "Account" | translate }}</span>
                </a>
                <a mat-menu-item>
                    <mat-icon>help</mat-icon>
                    <span>{{ "Help" | translate }}</span>
                </a>
                <a mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>{{ "Log out" | translate }}</span>
                </a>
            </mat-menu>
            <div class="notification-drawer" *ngIf="currentNotification">
                <mat-icon class="close" (click)="closeNotification()">close</mat-icon>
                <div class="body">
                    {{currentNotification}}
                </div>
            </div>
        </div>
    </div>
</mat-toolbar>
<audio #notification src="assets/audio/notification.mp3" preload="auto" autostart="0"></audio>
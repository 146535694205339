import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, ElementRef, TemplateRef, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LayoutUtilsService, LoaderService, RequestService, StoreService } from '../../../shared/services';
import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { ModalLanguageDialogComponent } from '../custom-language-dialog/custom-language-dialog.component';
import { NotificationService } from '../../services/notification.service';
import { EnumPipe } from '../../pipes';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public selectedUser: any;
  public languageSelected: string = 'en';
  public title = environment.serverName;
  public selectedOrganization: string = '';
  public userType: string = 'default';
  public dataType: string = 'organization';
  public dataTypeDisplay: string = 'Organization';
  public hasNew: boolean = false;
  public pageSize = 10;
  public date: Date = new Date();
  public screenWidth: number;
  public pictureLink: string = 'assets/images/profile.png';
  public isDnd: boolean = true;
  public currentRoute: string = '';
  public notificationAudio: any = undefined;
  public currentNotification: any = undefined;
  public notificationTimer: any = undefined;

  @Input() headerTemplate: TemplateRef<any>;
  @ViewChild('notification') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    if (ref)
      this.notificationAudio = ref.nativeElement;
  }

  constructor(private requestService: RequestService, public router: Router, private translate: TranslateService,
    public dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private firebaseHandler: FirebaseHandlersService, private notificationService: NotificationService, private enumPipe: EnumPipe, private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService) {
    if (localStorage.getItem('languageSelected')) {
      this.languageSelected = localStorage.getItem('languageSelected');
    }
    this.userType = this.requestService.getUserType();

    router.events.subscribe((data) => {
      if (data instanceof NavigationEnd ||
        data instanceof NavigationError) {
        this.currentRoute = activatedRoute.snapshot['_routerState'].url;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;

    this.subscriptions.push(this.notificationService.dnd.subscribe(async (isDnd) => {
      this.isDnd = isDnd;
    }));

    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.pictureLink = data.pictureLink;
        }
      })
    );

    this.subscriptions.push(this.notificationService.showNotificationUnRead.subscribe((show: any) => {
      if (this.currentRoute !== '/notifications' || !show)
        this.hasNew = show;
    }));

    this.subscriptions.push(this.firebaseHandler.currentMessage.subscribe((message: any) => {
      // console.log('message', message)
      if (message) {
        this.notificationService.showNotificationUnRead.next(true);
        this.notificationService.refreshData.next(true);

        if (!this.isDnd) {
          if (this.notificationAudio) {
            this.notificationAudio.load();
            this.notificationAudio.loop = 0;
            this.notificationAudio.volume = 0.25;
            this.notificationAudio.play().catch((error: any) => { });
          }

          this.currentNotification = message.data.message;
          if (this.notificationTimer) {
            clearTimeout(this.notificationTimer);
            this.notificationTimer = undefined;
          }
          this.notificationTimer = setTimeout(() => this.currentNotification = null, 10000);
        }
      }
      else
        this.currentNotification = null;
    }));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      minWidth: '40vw',
      width: 'fit-content',
      data: {
        dataType: 'user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: this.selectedUser,
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }

  public goHome() {
    this.router.navigate(['/']);
  }

  logout() {
    // this.loaderService.display(true);
    // this.adAuthService.logoutPopup().subscribe(() => {
    // Perform any cleanup or redirect to the login page
    // localStorage.removeItem('test_azure_data');
    // localStorage.removeItem('test_azure_token');
    console.log('User logged out');
    this.firebaseHandler.logout();
    // });
  }

  closeNotification() {
    this.currentNotification = null;
  }
}
